<template>
  <logo-svg aria-hidden="true" focusable="false" role="img" :class="classes" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import logoSvg from '~/assets/svg/chevron.svg'

const props = withDefaults(
  defineProps<{
    direction?: 'up' | 'down' | 'left' | 'right'
    class?: string
  }>(),
  {
    direction: 'up',
    class: ''
  }
)

const classes =
  props.class
    .trim()
    .split(' ')
    .reduce((acc: Record<string, boolean>, c) => {
      acc[c] = true
      return acc
    }, {}) ?? {}

classes[props.direction] = true
classes.chevron = true
</script>

<style lang="scss" scoped>
.up {
  transform: rotate(0);
}

.down {
  transform: rotate(180deg);
}

.left {
  transform: rotate(270deg);
}

.right {
  transform: rotate(90deg);
}
</style>
